<template>
  <div class="info">
    <top-bar :title="'办结问题'" :left="true"></top-bar>
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">问题信息</span>
        </template>
      </van-cell>
      <van-field v-model="dataForm.content" placeholder="请输入处理描述" input-align="right" rows="3" type="textarea" class="left"/>
      <van-row class="uploadBox">
        <van-col :span="24">
          <van-uploader v-model="fileList"   @delete="uploaddel" :after-read="uploadRead"/>
        </van-col>
      </van-row>
    </van-cell-group>

    <van-row class="btns">
      <van-col :span="24" >
        <van-button type="info" size="large" round @click="submit">确定办结</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'

import { mapState,mapMutations } from 'vuex'
export default {
  data () {
    return {
      resultShow: false,
      typeShow: false,
      managerShow: false,
      problemId: '',
      value: '',
      detailData: {},
      resultValue: '请选择',
      typeValue: '请选择',
      managerValue: '请选择',
      resultOptions:[
        { label: "无法处理", value: "0" },
        { label: "推送", value: "10" },
        { label: "处理中", value: "20" },
        { label: "处理完成", value: "30" }
      ],
      typeList:[],
      managerList: [],
      dataForm:{
        status:'',
        type:'',
        manager: '',
        id: '',
        fileMessages:[],
        content: ''
      },
      fileList:[]
    }
  },
  components :{
    topBar
  },
  methods: {
    getDetail () {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/problem/prepare'),
        method: 'post',
        params: this.$httpApp.adornParams({
          problemId: this.problemId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.dataForm.content = data.problemReported.content
          this.dataForm.fileMessages = data.problemReported.reportedFileList
          if (data.problemReported.reportedFileList && data.problemReported.reportedFileList.length != 0) {
            data.problemReported.reportedFileList.forEach(item=> {
              console.log(item);
              let u;
              u = getImageStream(item.relativePath)

              let url = {
                uid: this.fileList.length,
                id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: item.fileType,
                url: u,
                relativePath: item.relativePath
              }
              this.fileList.push(url);
            })
          }
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    submit () {
      if (this.dataForm.content.trim() === '') {return this.$toast.fail('失败:请先输入处理描述');}
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/problem/eventFinish'),
        method: 'post',
        params: this.$httpApp.adornParams({
          problemId: this.problemId,
          relativeUrl: this.dataForm.fileMessages.map(item => item.relativePath).join(','),
          solveUserId: this.$globalData.userInfo.userId || 0,
          finishContent: this.dataForm.content,
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success({message:'办结成功'})
          this.$router.push('/problem')
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.dataForm.fileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", 'files/problem/reported/temporary');
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.dataForm.fileMessages.push(data.fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    resultConfirm(val) {
      this.resultValue = val.label
      this.dataForm.status = val.value
      this.resultShow = false
    },
    typeConfirm(val) {
      this.typeValue = val.label
      this.dataForm.type = val.value
      this.typeShow = false
    },
    managerConfirm(val) {
      this.managerValue = val.label
      this.dataForm.manager = val.value
      this.managerShow = false
    }
  },
  created() {
    this.problemId = this.$route.query.id
    this.getDetail()
  }
}
</script>
